<template>
    <div>
        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/enterpriseHome/enterpriseContent' }">店铺首页</el-breadcrumb-item>
                <el-breadcrumb-item>全部商品 仪器</el-breadcrumb-item>
                <el-breadcrumb-item>BySort 3015/4020</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->
        <!-- 所有商品 -->
        <div class="commodityAll">
            <div class="l" v-loading="loading2">
                <div class="top">
                    <img src="@/assets/icon/fenl.png" alt="">
                    <div class="text">商品分类</div>
                </div>
                <el-menu collapse-transition :default-active="storeCategoryId" class="el-menu-vertical-demo"
                    background-color="#F8F8F8" text-color="#333333" active-text-color="#FF4242" @select="handleSelect">
                    <el-submenu :index="item.id + ''" v-for="item in storeClassificationList" :key="item.id">
                        <template slot="title">
                            <span>{{ item.typeName }}</span>
                        </template>
                        <el-menu-item :index="i.id + ''" v-for="i in item.children" :key="i.id">
                            <span
                                style="width: 150px;display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{
                                    i.typeName }}</span>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
            <div class="r" v-loading="loading">
                <div class="title">
                    <div class="l">
                        <div class="img">
                            <img src="@/assets/icon/qbsp.png" alt="">
                        </div>
                        <div class="text">全部商品</div>
                    </div>
                    <!-- <div class="r">
                        <div class="text">更多</div>
                        <div class="img">
                            <img src="@/assets/icon/gd.png" alt="">
                        </div>
                    </div> -->
                </div>
                <!-- 价格排序 tabs -->
                <div class="sorting">
                    <div class="tabsBox">
                        <div class="tabs" :style="{ width: sortList.length * 84 + 'px' }">
                            <div class="son" :class="[i.act ? 'tabsAct2' : '']" @click="sortCilck(index)"
                                :style="{ borderLeft: index == 1 || index == 3 ? '1px solid #e0e0e0' : '', borderRight: index == 1 ? '1px solid #e0e0e0' : '' }"
                                v-for="(i, index) in sortList" :key="index">
                                <div style="display: flex;align-items: center;justify-content: center;">
                                    <div>{{ i.name }}</div>
                                    <div v-if="index == 1" style="display: flex;align-items: center;margin-left: 5px;">
                                        <img :src="imagePath" style="width: 14px;" alt="">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- 无结果 -->
                <div v-if="storeCommodity.length == 0 && !loading">
                    <noResults style="margin-left: 50px;margin-top: 100px;" :keyWords="titleStore" :typeSouS="typeSouS">
                    </noResults>
                </div>
                <div class="tuijian" v-else>
                    <div class="son" @click="commodityClick(i)" v-for="(i, index) in storeCommodity" :key="index">
                        <div class="img">
                            <img :src="i.mainImage" alt="">
                        </div>
                        <div class="name">
                            {{ i.title }}
                        </div>
                        <div class="jiage">
                            <div class="l" v-if="i.price == 0">面议</div>
                        <div class="l" v-else>￥{{ i.price }}</div>
                            <div class="r">销量 {{ i.sales }}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 分页 -->
        <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :currentPage="paging.page"
            :page-size="paging.sizi"></paging>

    </div>
</template>

<script>
import { getStoreClassification, getStoreCommodity } from "@/utils/api/homeApi/index"
import paging from "@/components/paging.vue";
import noResults from "@/pages/searchPage/noResults.vue";
export default {
    name: 'enterpriseCommodity',
    components: {
        paging,
        noResults
    },
    data() {
        return {
            typeSouS: '',
            loading2: true,
            loading: true,
            // 店铺id
            ids: '',
            // 店铺商品
            storeCommodity: [],
            // 店铺分类
            storeClassificationList: [],
            // 价格logo
            imagePath: require('@/assets/icon/jgpx.png'),
            paging: {
                sizi: 12,
                page: 1,
                total: 0
            },
            type: 'ALL',
            titleStore: '',
            sortList: [
                {
                    name: '综合',
                    act: true
                },
                {
                    name: '价格',
                    act: false
                },
                {
                    name: '销量',
                    act: false
                },
                {
                    name: '新品',
                    act: false
                },
            ],
            // 分类id
            storeCategoryId: ''
        }
    },
    watch: {
        '$store.state.mallStoreTitle'(newVal, oldVal) {
            this.storeCategoryId = ''
            this.loading = true
            this.titleStore = newVal
            console.log(this.titleStore, 'this.titleStorenewValnewVal')
            this.getStoreCommodityList()
        },
    },
    mounted() {
        this.ids = localStorage.getItem('storeId')
        if (this.$route.query.storeCategoryId) {
            this.storeCategoryId = this.$route.query.storeCategoryId
        }
        if (this.$route.query.titleStore) {
            this.titleStore = this.$route.query.titleStore
        }
        // 获取店铺分类
        this.getStoreClassificationList()
        // 获取店铺商品
        this.getStoreCommodityList()
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.storeCategoryId = key
            this.paging.page = 1
            this.loading = true
            this.type = 'ALL'
            this.sortList.map((t) => (t.act = false));
            this.sortList[0].act = true;
            this.getStoreCommodityList()
        },
        sortCilck(index) {
            this.sortList.map((t) => (t.act = false));
            this.sortList[index].act = true;
            if (index == 0) {
                this.type = 'ALL'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            if (index == 1) {
                if (this.type == 'TALL' || this.type == 'LOW') {
                    if (this.type == 'TALL') {
                        this.type = 'LOW'
                        this.imagePath = require('@/assets/icon/jgpxb.png')
                    } else {
                        this.type = 'TALL'
                        this.imagePath = require('@/assets/icon/jgpxt.png')
                    }
                } else {
                    this.type = 'TALL'
                    this.imagePath = require('@/assets/icon/jgpxt.png')
                }
            }
            if (index == 2) {
                this.type = 'SALES'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            if (index == 3) {
                this.type = 'NEW'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            this.loading = true
            this.paging.page = 1
            this.getStoreCommodityList()
        },
        // 点击跳转商品
        commodityClick(i) {
            window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
        },
        // 获取店铺商品
        async getStoreCommodityList() {
            let data = {
                title: this.titleStore,
                type: this.type,
                storeCategoryId: this.storeCategoryId,
                storeId: this.ids,
                spuIds: '',
                currentPage: this.paging.page,
                pageSize: this.paging.sizi
            }
            const res = await getStoreCommodity(data)
            if (res.statusCode == 8201) {
                this.storeCommodity = res.data.records
                this.paging.total = res.data.total
                this.loading = false
            }
        },
        // 获取店铺商品分类
        async getStoreClassificationList() {
            const res = await getStoreClassification(this.ids)
            if (res.statusCode == 8201) {
                this.storeClassificationList = res.data
                this.loading2 = false
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getStoreCommodityList()
        },
    }
}
</script>

<style lang="less" scoped>
.breadcrumb {
    width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
}

// 全部商品
.commodityAll {
    width: 1200px;
    height: auto;
    border-radius: 8px;
    background-color: #fff;
    margin: 30px auto;
    padding: 30px;
    box-sizing: border-box;
    display: flex;

    >.l {
        width: 201px;
        overflow: hidden;
        user-select: none;

        >.top {
            width: 100%;
            height: 45px;
            background-color: #FF4242;
            padding: 0 15px;
            box-sizing: border-box;
            font-size: 18px;
            color: #fff;
            display: flex;
            align-items: center;

            >img {
                margin-right: 10px;
            }
        }
    }

    >.r {
        width: 900px;
        height: auto;
        margin-left: 30px;

        >.title {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            box-sizing: border-box;

            >.l {
                display: flex;
                align-items: center;

                >.img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
            }

            >.r {
                display: flex;
                align-items: center;
                cursor: pointer;

                >.text {
                    font-size: 12px;
                    color: #999;
                    transition: all .3s;
                }

                >.text:hover {
                    color: #ff4242;
                    transition: all .3s;
                }

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    margin-top: 2px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        >.tuijian {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            gap: 5px;
            // margin-top: 20px;

            >.son {
                width: 220px;
                height: 280px;
                background-color: #fff;
                border-radius: 4px;
                margin-bottom: 20px;
                padding: 10px;
                border: 1px solid #fff;
                box-sizing: border-box;
                transition: all .3s;
                cursor: pointer;

                >.img {
                    width: 200px;
                    height: 200px;

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }

                >.name {
                    font-size: 14px;
                    color: #000;
                    width: 100%;
                    margin-top: 10px;
                    white-space: nowrap;
                    transition: all .3s;
                    /* 禁止换行 */
                    overflow: hidden;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 超出部分显示省略号 */
                }

                >.jiage {
                    width: 100%;
                    height: 25px;
                    margin-top: 5px;
                    display: flex;
                    align-items: flex-end;

                    >.l {
                        font-size: 16px;
                        font-weight: bold;
                        color: #FF4242;
                        margin-right: 6px;
                    }

                    >.r {
                        font-size: 12px;
                        color: #666;
                        margin-bottom: 2px;
                    }
                }
            }

            >.son:hover {
                border: 1px solid #FFA5A5;
                transition: all .3s;

                >.name {
                    color: #FF4242;
                    transition: all .3s;
                }
            }
        }
    }
}

.sorting {
    width: auto;
    height: auto;
    margin: 20px auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 0px 10px;
    box-sizing: border-box;

    >.tabsBox {
        width: 100%;
        height: 45px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;

        >.tabs {
            display: flex;
            border: 1px solid #E0E0E0;
            background-color: #fff;
            box-sizing: border-box;
            color: #333;


            >.son {
                width: 84px;
                height: 30px;
                box-sizing: border-box;
                // border: 1px solid #fff;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
                transition: all .3s;
                cursor: pointer;
            }
        }

        >.my {
            font-size: 14px;
            color: #333;
        }
    }
}

.tabsAct2 {
    background-color: #FF4242;
    color: #fff;
    transition: all .3s;
}

// 分页
.paging {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}

/deep/ .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}

// /deep/ .el-breadcrumb__inner {
//     color: #ff4242 !important;
// }
</style>